<template>
  <validation-observer ref="formFAQ">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="question"
                  >Pertanyaan</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Pertanyaan"
                    rules="required"
                  >
                    <b-form-textarea
                      id="question"
                      v-model="models.question"
                      rows="5"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="answer"
                  >Jawaban</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Jawaban"
                    rules="required"
                  >
                    <b-form-textarea
                      id="answer"
                      v-model="models.answer"
                      rows="5"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col
                  v-if="isDetailPage"
                  sm="8"
                >
                  <b-form-input
                    id="Name"
                    :disabled="isDetailPage"
                    type="text"
                  />
                </b-col>
                <b-col
                  v-else
                  sm="5"
                >
                  <div
                    class="col-sm-12 d-flex justify-content-between"
                  >
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="badge badge-danger">Tidak Aktif</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end mt-1">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              {{ isDetailPage ? 'Kembali' : 'Batal' }}
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BButton, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'FormFAQ',
  metaInfo: {
    title: 'Form FAQ',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormInput,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        question: '',
        answer: '',
        status: true,
      },
      required,
    }
  },
  computed: {
    isDetailPage() {
      return this.$route.name === 'detail-faq'
    },
  },
  created() {
    this.getById()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.faq}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.question = data.question
            this.models.answer = data.answer
            this.models.status = data.status
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    save() {
      this.$refs.formFAQ.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (!this.$route.params.id) {
            request = this.$axios.post(api.faq, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.faq}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} faq`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
